import Instructors from "../components/instructors/Instructors_MAIN";
export default [
    {
        path: "/instruktorzy",
        name: "Instructors",
        component: Instructors,
        meta: {
            title: "Instruktorzy"
        }
    }
];
